import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

// import User from "../views/user/User.vue";
import Info from "../views/salary/Info.vue";
import ApplyHoliday from "../views/salary/ApplyHoliday.vue";
import ApplyBorrow from "../views/salary/ApplyBorrow.vue";
import ApplyGiveUpHoliday from "../views/salary/ApplyGiveUpHoliday.vue";
import Employer from "../views/salary/Employer.vue";
import AuditHoliday from "../views/salary/AuditHoliday.vue";
import AuditBorrow from "../views/salary/AuditBorrow.vue";
import AuditGiveUpHoliday from "../views/salary/AuditGiveUpHoliday.vue";
import ApplySalary from "../views/salary/ApplySalary.vue";
import AuditSalary from "../views/salary/AuditSalary.vue";
import SalaryManager from "../views/salary/SalaryManager.vue";
import Organisations from "../views/salary/Organisations.vue";
import ExchangeRate from "../views/salary/ExchangeRate.vue";
import Currency from "../views/salary/Currency.vue";
import SalaryItem from "../views/salary/SalaryItem.vue";
import HolidayItem from "../views/salary/HolidayItem.vue";

import Department from "../views/salary/Department.vue";
import Nationality from "../views/salary/Nationality.vue";
import Position from "../views/salary/Position.vue";
import WorkPermit from "../views/salary/WorkPermit.vue";
import PaymentItem from "../views/salary/PaymentItem.vue";
import Payment from "../views/salary/Payment.vue";

import AuditPayment from "../views/salary/AuditPayment.vue";
import ApplicationAmount from "../views/salary/ApplicationAmount.vue";
import AuditAmount from "../views/salary/AuditAmount.vue";

import Sellment from "../views/salary/Sellment.vue";
import AuditSellment from "../views/salary/AuditSellment.vue";

import Company from "../views/salary/Company.vue";
import CompanyAccount from "../views/salary/CompanyAccount.vue";
import AccountType from "../views/salary/AccountType.vue";
import AccountItem from "../views/salary/AccountItem.vue";
import Factory from "../views/salary/Factory.vue";

import HistorySellment from "../views/salary/HistorySellment.vue";
import HistoryPayment from "../views/salary/HistoryPayment.vue";
import HistoryPaymentAll from "../views/salary/HistoryPaymentAll.vue";
import History from "../views/salary/History.vue";
import HistoryPie from "../views/salary/HistoryPie.vue";

import HistorySellmentSelf from "../views/salary/HistorySellmentSelf.vue";
import HistoryPaymentSelf from "../views/salary/HistoryPaymentSelf.vue";

import ApplyBouns from "../views/salary/ApplyBouns.vue";
import AuditBouns from "../views/salary/AuditBouns.vue";

const routes = [
  {
    path: "/history-payment-all",
    name: "HistoryPaymentAll",
    component: HistoryPaymentAll,
  },
  {
    path: "/history-pie",
    name: "HistoryPie",
    component: HistoryPie,
  },
  {
    path: "/apply-bouns",
    name: "ApplyBouns",
    component: ApplyBouns,
  },
  {
    path: "/audit-bouns",
    name: "AuditBouns",
    component: AuditBouns,
  },
  {
    path: "/history",
    name: "History",
    component: History,
  },
  {
    path: "/self-history-sellment",
    name: "HistorySellmentSelf",
    component: HistorySellmentSelf,
  },
  {
    path: "/self-history-payment",
    name: "HistoryPaymentSelf",
    component: HistoryPaymentSelf,
  },
  {
    path: "/history-sellment",
    name: "HistorySellment",
    component: HistorySellment,
  },
  {
    path: "/history-payment",
    name: "HistoryPayment",
    component: HistoryPayment,
  },
  {
    path: "/department",
    name: "Department",
    component: Department,
  },
  {
    path: "/nationality",
    name: "Nationality",
    component: Nationality,
  },
  {
    path: "/position",
    name: "Position",
    component: Position,
  },
  {
    path: "/work-permit",
    name: "WorkPermit",
    component: WorkPermit,
  },
  {
    path: "/holiday-item",
    name: "HolidayItem",
    component: HolidayItem,
  },
  {
    path: "/salary-item",
    name: "SalaryItem",
    component: SalaryItem,
  },
  {
    path: "/",
    name: "Main",
    component: Info,
  },
  {
    path: "/info",
    name: "Info",
    component: Info,
  },
  {
    path: "/apply-giveup",
    name: "ApplyGiveUpHoliday",
    component: ApplyGiveUpHoliday,
  },
  {
    path: "/apply-holiday",
    name: "ApplyHoliday",
    component: ApplyHoliday,
  },
  {
    path: "/apply-borrow",
    name: "ApplyBorrow",
    component: ApplyBorrow,
  },
  {
    path: "/employer",
    name: "Employer",
    component: Employer,
  },
  {
    path: "/audit-giveup",
    name: "AuditGiveUpHoliday",
    component: AuditGiveUpHoliday,
  },
  {
    path: "/audit-holiday",
    name: "AuditHoliday",
    component: AuditHoliday,
  },
  {
    path: "/audit-borrow",
    name: "AuditBorrow",
    component: AuditBorrow,
  },
  {
    path: "/apply-salary",
    name: "ApplySalary",
    component: ApplySalary,
  },
  {
    path: "/audit-salary",
    name: "AuditSalary",
    component: AuditSalary,
  },
  {
    path: "/manager-salary",
    name: "SalaryManager",
    component: SalaryManager,
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: Organisations,
  },
  {
    path: "/exchange-rate",
    name: "ExchangeRate",
    component: ExchangeRate,
  },
  {
    path: "/currency",
    name: "Currency",
    component: Currency,
  },
  {
    path: "/payment-item",
    name: "PaymentItem",
    component: PaymentItem,
  },
  {
    path: "/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/company-account",
    name: "CompanyAccount",
    component: CompanyAccount,
  },
  {
    path: "/factory",
    name: "Factory",
    component: Factory,
  },
  {
    path: "/account-type",
    name: "AccountType",
    component: AccountType,
  },
  {
    path: "/account-item",
    name: "AccountItem",
    component: AccountItem,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/audit-payment",
    name: "AuditPayment",
    component: AuditPayment,
  },
  {
    path: "/application-amount",
    name: "ApplicationAmount",
    component: ApplicationAmount,
  },
  {
    path: "/audit-amount",
    name: "AuditAmount",
    component: AuditAmount,
  },
  {
    path: "/sellment",
    name: "Sellment",
    component: Sellment,
  },
  {
    path: "/audit-sellment",
    name: "AuditSellment",
    component: AuditSellment,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
