import axios from "axios";
import qs from "qs";

let config = {
  headers: {
    product: "salary",
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};

export default class EmployerDetail {
  getEmployerDetailInfo(employer_id) {
    return axios.get(
      `/api/manager/employer-detail/`,
      {
        params: {
          employer_id: employer_id,
        },
      },
      config
    );
  }
  getEmployerDetailReportList(employer_ids) {
    return axios.get(
      `/api/manager/employer-detail/report/list`,
      {
        params: {
          employer_ids: employer_ids,
          page: 1,
          page_num: 10000,
        },
      },
      config
    );
  }
}
