export function dateFormate(dateString, formate) {
  let date = new Date(dateString);
  const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
  date.setHours(GMT_8);
  let formatted_date = "";
  switch (formate) {
    case "YYYY-MM-DD":
      formatted_date =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      break;
    case "YYYY-MM":
      formatted_date = date.getFullYear() + "-" + (date.getMonth() + 1);
      break;
    case "YYYY年MM月DD日":
      formatted_date =
        date.getFullYear() +
        "年" +
        (date.getMonth() + 1) +
        "月" +
        date.getDate() +
        "日";
      break;
    case "DD-MM-YYYY":
      formatted_date =
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
      break;
  }
  return formatted_date;
}
