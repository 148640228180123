<script setup>
import {
  reactive,
  onMounted,
  onBeforeMount,
  onUpdated,
  toRefs,
  computed,
  watch,
} from "vue";
import { thousands } from "../tool/thousands";
import { dateFormate } from "../tool/formate";
import { useStore } from "vuex";
const store = useStore();
// const i18n = useI18n();
// const toast = useToast();
const data = reactive({
  department: [],
});
const beforemounted = onBeforeMount(async () => {});
const mounted = onMounted(async () => {});
</script>

<template>
  <div
    style="
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    "
  >
    <div
      style="
        width: 100%;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      "
    >
      <div style="width: 25%; text-align: left">
        <img style="height: 80px; width: 80px" :src="store.state.logo" />
      </div>
      <div style="width: 75%; text-align: center; margin-left: -10%">
        <h2>
          {{
            store.state.payment == null ? "" : store.state.payment.company_name
          }}
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;繳款單 / Payment
          Comfirmation
        </h2>
      </div>
      <!-- <div style="width: 100%; text-align: right">
        系統單號：
        {{ store.state.payment == null ? "" : store.state.payment.id }}
      </div> -->
    </div>

    <div style="border: 1px solid black; width: 100%">
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          繳款部門(Department)：
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          <div v-if="store.state.payment != null">
            <span
              :key="index"
              v-for="(name, index) in store.state.payment.department"
            >
              {{ name }}
            </span>
          </div>
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          系統單號<br />(Number)：
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ store.state.payment == null ? "" : store.state.payment.id }}
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          客戶名稱<br />(Customer)：
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment == null ? "" : store.state.payment.factory_name
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          繳款日期<br />(DATE)：
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment == null
              ? ""
              : dateFormate(store.state.payment.created_at, "YYYY-MM-DD")
          }}
        </div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          帳戶類別<br />(Payment Terms)：
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        ></div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          帳戶來源<br />(Payment Type))：
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null
              ? store.state.payment.payment_method == "現金" ||
                store.state.payment.payment_method == "Cash"
                ? "現金(Cash)"
                : store.state.payment.payment_method == "轉帳" ||
                  store.state.payment.payment_method == "Transfer"
                ? "轉帳(Transfer)"
                : store.state.payment.payment_method == "刷卡" ||
                  store.state.payment.payment_method == "Card"
                ? "刷卡(Card)"
                : ""
              : ""
          }}
        </div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          繳交金額<br />(AMOUNT)：
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            store.state.payment != null
              ? thousands(parseFloat(store.state.payment.total_dollar))
              : "0.00"
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          幣別<br />(Currency)：
        </div>
        <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        ></div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          票據內容<br />(Cheque Ref)：
        </div>
        <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          <div
            style="
              border-bottom: 1px solid black;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 10px;
            "
          >
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              金額<br />
              Amount
            </div>
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              銀行名稱<br />
              Bank
            </div>
            <div
              style="
                width: 32%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              票據號碼<br />
              Number
            </div>
          </div>
          <div
            style="
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 10px;
            "
          >
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              &nbsp;<br />
              &nbsp;<br />
            </div>
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              &nbsp;<br />
              &nbsp;<br />
            </div>
            <div
              style="
                width: 32%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              &nbsp;<br />
              &nbsp;<br />
            </div>
          </div>
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          票據到期日<br />(Due Date)：
        </div>
        <div style="width: 27.5%; text-align: center">
          <div
            style="
              border-bottom: 1px solid black;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 10px;
            "
          >
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              年<br />
              Year
            </div>
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              月<br />
              Month
            </div>
            <div
              style="
                width: 32%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              日<br />
              Day
            </div>
          </div>
          <div
            style="
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              font-size: 10px;
            "
          >
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              &nbsp;<br />
              &nbsp;<br />
            </div>
            <div
              style="
                border-right: 1px solid black;
                width: 33%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              &nbsp;<br />
              &nbsp;<br />
            </div>
            <div
              style="
                width: 32%;
                text-align: center;
                padding-top: 5px;
                padding-bottom: 5px;
              "
            >
              &nbsp;<br />
              &nbsp;<br />
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="
            width: 100%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          沖銷單據號碼<br />
          Invoice Number
        </div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 14%; text-align: center"
        >
          &nbsp; 品項 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 14%; text-align: center"
        >
          &nbsp; 會計科目 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 14%; text-align: center"
        >
          &nbsp; 數量 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 14%; text-align: center"
        >
          &nbsp; 單價 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 14%; text-align: center"
        >
          &nbsp; 總金額 &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 14%; text-align: center"
        >
          &nbsp; 發票 &nbsp;
        </div>
        <!-- <div
          style="border-right: 1px solid black; width: 24%; text-align: center"
        >
          &nbsp; VAT &nbsp;
        </div> -->
        <div style="width: 14%; text-align: center">&nbsp; VAT &nbsp;</div>
      </div>
      <div
        :key="index"
        v-for="(content, index) in store.state.payment_content"
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          font-size: 12px;
        "
      >
        <div
          style="
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.payment_item_chinese_name }}({{
            content.payment_item_english_name
          }})
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.account_item_name }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.total_count }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ thousands(content.dollar) }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            content.payment_item_taxed == 1
              ? thousands(
                  parseFloat(
                    parseFloat(content.total_dollar) + parseFloat(content.vat)
                  )
                )
              : thousands(parseFloat(content.total_dollar))
          }}
        </div>
        <div
          style="
            border-right: 1px solid black;
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ content.invoice_number }}
        </div>
        <div
          style="
            width: 14%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            content.payment_item_taxed == 1
              ? thousands(parseFloat(content.vat))
              : "0.00"
          }}
        </div>
        <!-- <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{ parseFloat(content.total_dollar).toFixed(2) }}
        </div> -->
        <!-- <div
          style="
            border-right: 1px solid black;
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            content.payment_item_taxed == 1
              ? parseFloat(content.total_dollar * 0.15).toFixed(2)
              : 0.0
          }}
        </div> -->
        <!-- <div
          style="
            width: 24%;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          {{
            content.payment_item_taxed == 1
              ? parseFloat(
                  parseFloat(content.total_dollar) +
                    parseFloat(content.total_dollar) * 0.15
                ).toFixed(2)
              : parseFloat(content.total_dollar).toFixed(2)
          }}
        </div> -->
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div style="width: 99%; text-align: left; font-size: 12px">
          &nbsp; 合計：{{
            store.state.payment != null
              ? thousands(parseFloat(store.state.payment.total_dollar))
              : "0.00"
          }}
          &nbsp;<br />
          &nbsp; 1.現金(Cash) 2.銀行存款轉帳 (T/T) 3.票據(Cheque) &nbsp;<br />
          &nbsp; 二.款項來源(Payment Type): &nbsp;<br />
          &nbsp; 1.銷貨收入 (sales revenue) 5.下腳出售收入 (waste revenue)
          9.佣金收入(commision revenue) 13.保證金收回(deposit refund) &nbsp;<br />
          &nbsp; 2.暫付款繳回(prepayment refund) 6.租金收入 (rent revenue)
          10.利息收入(interest revenue) 14.原物料收入(material revenue)
          &nbsp;<br />
          &nbsp; 3.退稅款 (tax refund) 7.賠償金退回(compensation refund)
          11.出售固定資產 (asset-selling revenue) 15.其他收入請說明(others)
          &nbsp;<br />
          &nbsp; 4.代收款項 (collections) 8.客戶存入保證金(collections for
          deposit) &nbsp; 12.溢付款退回(reimbusement) &nbsp;<br />
          &nbsp; 三.款項說明欄務必填寫清楚 , 並請註明幣別。
        </div>
      </div>

      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; 產區總經理 &nbsp;<br />
          (Eswatini Managing Director)
        </div>
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; 財務主管 &nbsp;<br />
          (Financial Director)
        </div>
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; 出納 &nbsp;<br />
          (Discharge)
        </div>
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; 繳款單位主管 &nbsp;<br />
          (Billing Unit Supervisor)
        </div>
        <div style="width: 19%; text-align: center">
          &nbsp; 繳款人 &nbsp;<br />
          (Payee)
        </div>
      </div>
      <div
        style="
          border-bottom: 1px solid black;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        "
      >
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div
          style="border-right: 1px solid black; width: 19%; text-align: center"
        >
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
        <div style="width: 19%; text-align: center">
          &nbsp; &nbsp;<br />&nbsp;<br />
          &nbsp;<br />
          &nbsp;<br />
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.button-up {
  background-color: rgb(13, 82, 105); /* Green */
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
}
</style>
